
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import PolicyCertificateUpload from "@/components/reusable/policy-information/documents-uploads/PolicyCertificateUpload.vue";

export default defineComponent({
  name: "broker-policy-certificate-upload",
  components: { PolicyCertificateUpload },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Certificate", [
        "Broker",
        "Policies",
        "Upload"
      ]);
      MenuComponent.reinitialization();
    });
  },
  created() {
    //Set page title
    document.title =
      "Broker Policy Certificate Upload | " + process.env.VUE_APP_TITLE;
  }
});
